import styled, { css } from 'styled-components';
import { Icon } from 'core/icon/icon';
import { media } from 'styles/media';

export const StyledProductCard = styled.div`
  position: relative;
`;

export const StyledColorsSelector = styled.div`
  margin-top: 5px;
  cursor: pointer;
`;

export const StyledSvgStar = styled(Icon)`
  ${({ theme }) => css`
    position: absolute;
    top: ${theme.spacing.s2};
    right: ${theme.spacing.s2};
    cursor: pointer;
    z-index: 3;

    ${media.lessThan('sm')`
      top: ${theme.spacing.s1};
      right: ${theme.spacing.s1};
    `}
  `}
`;

export const StyledNewBadge = styled.div`
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  z-index: 1;
  display: flex;

  ${media.lessThan('sm')`
    top: 8px;
    right: 8px;
    left: 8px;
  `}
`;

export const StyledStockBadge = styled.div`
  margin-top: 0.5rem;
`;
