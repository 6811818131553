import { useCallback } from 'react';
import { useAuth } from 'context/auth.context';
import { useSignInModal } from 'modals/sign-in';
import { User } from 'types/user.type';
import { api } from 'services/api';
import { CartProduct } from 'services/api/cart/cart-api.types';
import { useCart } from 'context/cart.context';
import { useNotification } from 'context/notification.context';
import { trackAnalyticsEvent } from 'utils/analytics';
import { extractAnalyticsProductDataFromSku } from 'modules/shop/product/product.adapter';
import { useProduct } from 'context/product.context';

export interface UseWishlistProps {
  sku: string;
  source?: string;
  analyticsEvent?: string;
  signUpAnalyticsEvent?: string;
}

export const useWishlist = ({
  sku,
  source,
  analyticsEvent,
  signUpAnalyticsEvent,
}: UseWishlistProps) => {
  const [{ authenticated }] = useAuth();
  const [{ wishlist }, cartDispatch] = useCart();
  const { notify } = useNotification();
  const [{ records }] = useProduct();

  const updateWishlist = useCallback(
    async (
      wishlist: CartProduct[],
      comingFromSignIn: boolean = false,
      productSku: string = null,
    ) => {
      const skuIndex = wishlist.findIndex(
        item => item.sku === (productSku || sku),
      );

      let updatedWishlist: CartProduct[] = [...wishlist];

      if (skuIndex > -1 && !comingFromSignIn) {
        updatedWishlist.splice(skuIndex, 1);
      } else {
        updatedWishlist = [...updatedWishlist, { sku: productSku || sku }];
      }

      const { data, success } = await api.user.updateWishlist({
        wishlist: updatedWishlist,
      });

      if (success) {
        cartDispatch({
          type: 'UPDATE_WISHLIST',
          wishlist: data.user.data.wishlist,
        });

        const inWishlist = skuIndex > -1;

        notify({
          messageTx: inWishlist
            ? 'notifications.removeFromWishlist'
            : 'notifications.addToWishlist',
        });

        const record = records.find(item => item.sku === sku);

        if (record) {
          trackAnalyticsEvent(
            inWishlist
              ? 'Product Removed from Wishlist'
              : 'Product Added to Wishlist',
            extractAnalyticsProductDataFromSku(record),
          );
        }
      }
    },
    [sku, records?.length],
  );

  const onSignInModalClose = useCallback(async (user: User) => {
    if (user) {
      await updateWishlist(user.wishlist, true);
    }
  }, []);

  const { showModal: showSignInModal } = useSignInModal({
    source,
    signUpEventName: 'Sign Up - Wishlist Product Page',
    onClose: onSignInModalClose,
  });

  const toggleSkuInWishlist = useCallback(async () => {
    if (analyticsEvent) {
      trackAnalyticsEvent(analyticsEvent, { sku });
    }

    if (authenticated) {
      await updateWishlist(wishlist ?? []);
    } else {
      await showSignInModal();
    }
  }, [sku, authenticated, wishlist]);

  return {
    toggleSkuInWishlist,
    updateWishlist,
    skuInWishlist: wishlist.some(item => item.sku === sku),
  };
};
