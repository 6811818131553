import React, { memo, useEffect, useState } from 'react';
import { ProductCardProps } from 'layout/product-card/product-card.props';
import { ProductCardPresets } from 'layout/product-card/product-card.presets';
import { ImageContainer } from 'layout/image-container';
import { StackedContainer } from 'layout/stacked-container';
import { FlexContainer } from 'layout/flex-container';
import { Typography } from 'core/typography';
import {
  StyledColorsSelector,
  StyledNewBadge,
  StyledProductCard,
  StyledStockBadge,
  StyledSvgStar,
} from 'layout/product-card/product-card.styles';
import { formatCurrency } from 'utils/formaters';
import { ColorsList } from 'core/colors-list';
import Link from 'next/link';
import { useWishlist } from 'modules/shop/product/product.hooks';
import { Badge } from 'core/badge';
import { Photo, SKU } from 'types/product.type';
import { BoxContainer } from 'layout/box-container';
import { extractProductPageUrlFromSku } from 'modules/shop/product/product.adapter';
import { Icon } from 'core/icon';
import capitalize from 'lodash/capitalize';

export const ProductCard = memo((props: ProductCardProps) => {
  const {
    product,
    aspectRatio,
    selectedColor,
    showColorSelector,
    imageHeight,
    designerVisible,
    canBeWhitelisted,
    showNewBadge,
    nameTypographyProps,
    descriptionTypographyProps,
    priceTypographyProps,
  } = Object.assign({}, ProductCardPresets, props);
  const [activeSku, setActiveSku] = useState<SKU>(product || {});
  const [color, setColor] = useState('');

  const colors = activeSku?.options?.colors?.map(color => color.code);

  const { skuInWishlist, toggleSkuInWishlist } = useWishlist({
    sku: activeSku?.sku,
    source: 'Wishlist',
    analyticsEvent: 'Toggle Wishlist - Product Card',
    signUpAnalyticsEvent: 'Sign Up - Wishlist Product Card',
  });

  useEffect(() => {
    if (color) {
      const currentSku = activeSku.sku.split('-');
      currentSku[2] = color;
      const newSku = currentSku.join('-');

      if (!product.skusData) {
        throw new Error(
          'If showColorSelector specified, a property `skusData` containing skus info needs to be provided as part of the product object.',
        );
      }

      setActiveSku(product.skusData[newSku] || product);
    }
  }, [color]);

  useEffect(() => {
    if (product.sku) {
      setActiveSku(product);
    }
  }, [product.sku]);

  useEffect(() => {
    if (!!product.options && selectedColor) {
      const colorInOptions = product.options.colors.some(
        item => item.code === selectedColor,
      );
      setColor(
        colorInOptions ? selectedColor : product.options?.colors[0]?.code,
      );
    }
  }, [selectedColor]);

  if (!activeSku) {
    return null;
  }

  const photos = activeSku.photoUrls?.photos?.lifestyleset?.length
    ? activeSku.photoUrls?.photos
    : activeSku.photoUrls?.photos;

  const overlaySrc = photos?.lifestyleset?.length
    ? photos.lifestyleset[0].mobile
    : '';

  const selectedPhoto = photos.product?.find(
    (photo: Photo) => photo.fileName === activeSku.selectedProductPhoto,
  );

  const firstPhoto = photos?.product?.length
    ? photos.product[0]
    : { desktop: '', mobile: '' };

  return (
    <StyledProductCard>
      {showNewBadge && activeSku?.newProduct && (
        <StyledNewBadge>
          <Badge text="NEW" color="primary" />
        </StyledNewBadge>
      )}

      <Link
        href="/products/[id]/[skuInfo]/[sku]"
        as={extractProductPageUrlFromSku(activeSku)}>
        <a draggable="false">
          <StackedContainer maxWidth="1fr" padding="unset">
            <ImageContainer
              src={{
                lg: (selectedPhoto || firstPhoto)?.desktop,
                sm: (selectedPhoto || firstPhoto)?.mobile,
              }}
              overlaySrc={overlaySrc}
              alt={`${activeSku.fullSkuName} by ${activeSku.designer?.name}`}
              imageHeight={imageHeight}
              source="external"
              aspectRatio={aspectRatio}
              style={{ cursor: 'pointer' }}
              backgroundColor="cream"
            />
            <StackedContainer padding="unset" gap="0" maxWidth="1fr">
              <FlexContainer justifyContent="space-between">
                <Typography
                  text={activeSku.fullProductName}
                  textStyle="bold"
                  {...nameTypographyProps}
                />
                <BoxContainer hidden={{ lg: false, md: true }}>
                  <Typography
                    text={formatCurrency(activeSku.price)}
                    color="grey"
                    {...priceTypographyProps}
                  />
                </BoxContainer>
              </FlexContainer>
              {designerVisible && (
                <Typography
                  text={`${activeSku.designer?.name}`}
                  color="grey"
                  variant="p3"
                />
              )}
              <Typography
                text={`${capitalize(activeSku.color?.name)} ${
                  activeSku.dimension?.height
                }" tall`}
                color="grey"
                variant="p3"
                {...descriptionTypographyProps}
              />
              <BoxContainer hidden={{ lg: true, md: false }}>
                <Typography
                  text={formatCurrency(activeSku.price)}
                  color="grey"
                  variant="p3"
                />
              </BoxContainer>
            </StackedContainer>
          </StackedContainer>
        </a>
      </Link>
      {canBeWhitelisted && (
        <StyledSvgStar
          name={skuInWishlist ? 'star' : 'star-outlined'}
          strokeColor="gold"
          color="gold"
          size="1.8rem"
          onClick={toggleSkuInWishlist}
        />
      )}

      {showColorSelector && (
        <StyledColorsSelector>
          <ColorsList
            colors={colors ?? []}
            selected={color}
            onChange={setColor}
          />
        </StyledColorsSelector>
      )}
      {activeSku.stocks > 0 && !showColorSelector && (
        <Link
          href="/products/[id]/[skuInfo]/[sku]"
          as={extractProductPageUrlFromSku(activeSku)}>
          <a draggable="false">
            <StyledStockBadge>
              <Badge
                tx="common.quickShip"
                textStyle="bold"
                radius="2rem"
                icon={<Icon name="ray" color="primary" />}
                gap="3px"
              />
            </StyledStockBadge>
          </a>
        </Link>
      )}
    </StyledProductCard>
  );
});
