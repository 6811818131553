import { ProductCardProps } from 'layout/product-card/product-card.props';

export const ProductCardPresets: Partial<ProductCardProps> = {
  aspectRatio: '1:1',
  imageHeight: '100%',
  designerVisible: true,
  nameTypographyProps: {},
  descriptionTypographyProps: {},
  priceTypographyProps: {},
  canBeWhitelisted: true,
  showNewBadge: true,
};
